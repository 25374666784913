import {
  computed,
  onUnmounted,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { QueryStateStorageConfig } from '@/utils/storage';
import {
  DataExtract,
  DataExtractsResponse,
  DataExtractType,
} from '@/types/data-extracts';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import {
  CreateDataExtractRequest,
  getAllExtracts,
  startNewExtractJob,
} from '@/services/data-extracts.service';
import { DataTableState } from '@/utils/data-table-state';
import { showStandardHttpErrorNotification } from '@/utils/util';
import { TableHeader } from '@/types/table-options';

export const defaultDataExtractFooter = {
  'items-per-page-text': 'Extracts per page:',
  'items-per-page-options': [10, 25, 50],
};

export const defaultDataExtractHeaders: TableHeader[] = [
  { text: 'Date Requested', value: 'created_at', sortable: true },
  { text: 'Date Range', value: 'date_range', sortable: false },
  { text: 'Extract Expiration', value: 'download_expires_at', sortable: false },
  { text: 'Type', value: 'type', sortable: false, align: 'center' },
  { text: 'Status', value: 'status', sortable: true, align: 'center' },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
    align: 'center',
    class: 'tw-whitespace-no-wrap',
  },
];

export function getTypeDescription(type: DataExtractType): string {
  switch (type) {
    case DataExtractType.apiActivity:
      return 'API Activity';
    case DataExtractType.patchHistory:
      return 'Patch History';
    default:
      return 'Unknown';
  }
}

export function getTypeTooltip(type: DataExtractType): string {
  switch (type) {
    case DataExtractType.apiActivity:
      return 'This extract provides a record of actions taken through the Console and API.';
    case DataExtractType.patchHistory:
      return 'This extract provides a history of patches.';
    default:
      return 'Unknown';
  }
}

export function useDataExtracts(context: SetupContext) {
  const orgId = computed(() => Number(context.root.$route.query.o));
  const extracts = ref<DataExtract[]>([]);
  const extractsSize = ref<number>(0);
  let jobPollingTimerId;
  let lastApiQuery: string;

  watch(
    orgId,
    (next, prev) => {
      if (prev && next !== prev) {
        updateQueryState({ o: next });
      }
    },
    {
      immediate: true,
    },
  );

  onUnmounted(() => {
    clearJobPollingTimer();
  });

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-data-extract-report-prefs',
    store: localStorage,
  };

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize(
      context.root.$route,
      context.root.$router,
      {
        o: { defaultValue: orgId.value },
        limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
        sort: { defaultValue: 'created_at:desc' },
        page: { defaultValue: 1 },
        columns: { defaultValue: [] },
        type: { defaultValue: undefined, apiKeyTranslation: 'type:equals' },
      },
      {
        storageConfig,
        callback: ({ apiQuery }) => {
          getDataExtract(apiQuery);
          lastApiQuery = apiQuery;

          if (!jobPollingTimerId) {
            startJobPollingTimer();
          }
        },
      },
    );

  function getDataExtract(
    query: string,
    showLoader?: boolean,
  ): Promise<DataExtractsResponse[] | void> {
    return getAllExtracts(query, showLoader)
      .then((response) => {
        extracts.value = response.results!;
        extractsSize.value = response.size!;
      })
      .catch(showStandardHttpErrorNotification);
  }

  function createDataExtract(
    request: CreateDataExtractRequest,
  ): Promise<DataExtract> {
    return startNewExtractJob(request, orgId.value);
  }

  function startJobPollingTimer() {
    jobPollingTimerId = setInterval(() => {
      getDataExtract(lastApiQuery, false);
    }, 30000);
  }

  function clearJobPollingTimer() {
    clearInterval(jobPollingTimerId);
  }

  return {
    createDataExtract,
    updateQueryState,
    tableState,
    queryState,
    extracts,
    extractsSize,
  };
}
