var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ax-reports",attrs:{"fluid":""}},[_c('ax-zone-breadcrumbs'),_c('v-card-text',[_c('h1',[_vm._v(_vm._s(_vm.$t('reports.dataExtracts.heading')))]),_c('hr',{staticClass:"tw-mb-4"}),_c('ax-button',{attrs:{"mode":"primary"},on:{"click":_vm.modals.generate.open}},[_vm._v(" "+_vm._s(_vm.$t('reports.dataExtracts.generateNew:button'))+" ")]),_c('ax-table',{staticClass:"tw-w-full tw-mt-5",attrs:{"items":_vm.extracts,"headers":_vm.defaultDataExtractHeaders,"footer-props":_vm.defaultDataExtractFooter,"options":_vm.tableState,"column-select":false,"item-key":"keyId","loading-text":_vm.$t('reports.dataExtracts.table.loading'),"no-data-text":_vm.$t('reports.dataExtracts.table.noData'),"server-items-length":_vm.extractsSize},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToZonedTime(item.created_at))+" ")]}},{key:"item.date_range",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.formatUTCToZonedTime( item.parameters.start_time )) + " - " + (_vm.formatUTCToZonedTime(item.parameters.end_time))))+" ")]}},{key:"item.download_expires_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToZonedTime(item.download_expires_at))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeDescription(item.type))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'queued' || item.status === 'running')?_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center"},[_c('ax-loader-bars'),_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.status))])],1):_c('div',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ax-button',{attrs:{"mode":"secondary","small":"","disabled":item.status === 'queued' ||
            item.status === 'running' ||
            item.status === 'expired' ||
            item.status === 'failed',"href":("" + (item.download_url))}},[_c('span',{staticClass:"tw-text-left"},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.download'))+" ")])])]}}])})],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.generate.opened),callback:function ($$v) {_vm.$set(_vm.modals.generate, "opened", $$v)},expression:"modals.generate.opened"}},[_c('ax-data-extract-generate-modal',{on:{"close":_vm.modals.generate.close,"submit":function (val) { return _vm.submitNewRequest(val); }}})],1),_c('ax-modal',{attrs:{"persistent":""},model:{value:(_vm.modals.generateConfirm.opened),callback:function ($$v) {_vm.$set(_vm.modals.generateConfirm, "opened", $$v)},expression:"modals.generateConfirm.opened"}},[_c('ax-data-extract-generate-confirmation-modal',{on:{"close":_vm.modals.generateConfirm.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }