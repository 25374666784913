/* eslint-disable max-classes-per-file */
export enum DataExtractType {
  patchHistory = 'patch-history',
  apiActivity = 'api-activity',
}

export class DataExtractsResponse {
  metadata?: DataExtractMetadata;

  size?: number;

  results?: DataExtract[];

  constructor(init: Partial<DataExtractsResponse>) {
    Object.assign(this, init);
  }
}

export class DataExtractMetadata {
  current_page?: number;

  limit?: number;

  total_pages?: number;

  constructor(init: DataExtractMetadata) {
    Object.assign(this, init);
  }
}

// DataExtractParameters represents the parameters provided when generating the extract. In the future,
// the structure of this object may change based on the supported parameters for a DataExtract type.
export class DataExtractParameters {
  start_time?: string;

  end_time?: string;

  constructor(init: DataExtract) {
    Object.assign(this, init);
  }
}

export class DataExtract {
  id?: number;

  type?: DataExtractType;

  organization_id?: number;

  user_id?: number;

  status?: string;

  is_completed?: boolean;

  created_at?: string;

  download_expires_at?: string;

  download_url?: string;

  parameters?: DataExtractParameters;

  constructor(init: DataExtract) {
    Object.assign(this, init);
  }
}
