import { AxiosRequestConfig } from 'axios';
import {
  DataExtractsResponse,
  DataExtract,
  DataExtractParameters,
  DataExtractType,
} from '@/types/data-extracts';

import httpClient from './http-client';

const DATA_EXTRACTS_ENDPOINT = '/data-extracts';

export interface CreateDataExtractRequest {
  type: DataExtractType;
  parameters: DataExtractParameters;
}

export const getAllExtracts = (
  query: string,
  showLoader = true,
): Promise<DataExtractsResponse> =>
  httpClient
    .get(`${DATA_EXTRACTS_ENDPOINT}?${query}`, {
      loaderEnabled: showLoader,
    } as AxiosRequestConfig)
    .then((response) => response.data);

export const startNewExtractJob = (
  payload: CreateDataExtractRequest,
  orgId: number,
): Promise<DataExtract> => {
  return httpClient
    .post(`${DATA_EXTRACTS_ENDPOINT}?o=${orgId}`, {
      ...payload,
    })
    .then((response) => response.data);
};
